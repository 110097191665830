export const PilotPostPaymentPage = () => {
  return (
    <div className="landing-page">
      <div className="landing-page-left-side">
        <div className="top-area">
          <div className="landing-page-logo">
            <h1>
              quant<span className="text-highlight">essential</span>
            </h1>
          </div>
        </div>
        <div className="landing-page-value-prop1">
          Passing quant interviews <strong>does not have to be hard</strong>.
          Get quant offers fast by studying the <strong>right</strong> way.
        </div>
        <div className="landing-page-value-prop2">
          Learn to solve these 30 real interview problems, and you can land a
          quant offer.
        </div>
      </div>
      <div className="landing-page-right-side">
        <div className="landing-page-problem-box-container">
          <div className="landing-page-sign-up-value-prop">
            <h1>Thank you</h1>
            for signing up to the QuantEssential Pilot. You will receive an
            email shortly with the first problem.
            <br></br>
            <br></br>
            In the meantime, feel free to reach out to me at:
            <br></br>
            email: sam.a.liu3@gmail.com
            <br></br>
            discord: smellerbeez
          </div>
        </div>
      </div>
    </div>
  );
};
