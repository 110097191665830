import React from "react";

interface ProblemNavBarProps {
  activeTab: string;
  onTabClick: (tabName: string) => void;
}

export const ProblemNavBar: React.FC<ProblemNavBarProps> = ({
  activeTab,
  onTabClick,
}) => {
  return (
    <div className="navbar">
      <button
        className={`nav-item ${activeTab === "description" ? "active" : ""}`}
        onClick={() => onTabClick("description")}
      >
        Description
      </button>
      <button
        className={`nav-item ${activeTab === "solution" ? "active" : ""}`}
        onClick={() => onTabClick("solution")}
      >
        Solution
      </button>
    </div>
  );
};
