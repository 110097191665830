import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

type MarkTexProps = {
  children: string;
};

export const MarkTex = (props: MarkTexProps) => {
  return (
    <ReactMarkdown
      className="marktex"
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
    >
      {props.children}
    </ReactMarkdown>
  );
};
