import { useState } from "react";
import { MarkTex } from "./MarkTex";

type AccordianProps = {
  title: string;
  content: string;
};

export const Accordian = ({ title, content }: AccordianProps) => {
  const [toggled, setToggled] = useState(false);

  const toggle = () => {
    setToggled(!toggled);
  };

  return (
    <div className="toggle-accordian">
      <div className="accordian-title" onClick={() => toggle()}>
        {title}
        <span>{toggled ? "-" : "+"}</span>
      </div>
      <div className={toggled ? "accordian-content show" : "accordian-content"}>
        <MarkTex>{content}</MarkTex>
      </div>
    </div>
  );
};
