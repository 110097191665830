import { useState } from "react";
// import { pilotSignUp } from "../api/userAPI";
import { getStripeURLWithPrefilledEmail } from "../utils/stripeUtils";

export const PilotSignUpForm = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const stripeURL = getStripeURLWithPrefilledEmail(email);
    window.location.href = stripeURL;
  };

  return (
    <>
      <div className="landing-page-sign-up-value-prop">
        The quant interview is a <strong>winnable</strong> game. No prior quant
        experience necessary. I'll teach you the right way of thinking to{" "}
        <strong>solve any interview problem</strong>.
      </div>
      <br></br>
      <div className="landing-page-sign-up-value-prop">
        <strong>Only 30 spots</strong> in the pilot! Get 27 more interview
        problems and solutions to ace any quant interview.
      </div>
      <form onSubmit={onSubmit} className="sign-up-form">
        <br></br>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        {error && <p className="text-error">{error}</p>}{" "}
        <button type="submit" className="btn btn-primary">
          Sign up for personalized interview prep
        </button>
      </form>
    </>
  );
};
