import { useEffect, useState } from "react";

import "../styles/global.css";
// import { Problem, getProblemBySlug } from "../api/problemsAPI";
import { Problem, getProblemBySlug } from "../data";
import { MarkTex } from "./MarkTex";
import { ProblemNavBar } from "./ProblemNavBar";
import { Accordian } from "./Accordian";
import { AnswerInput } from "./AnswerInput";

type ProblemProps = {
  problemSlug: string;
  onSubmit: (answer: string) => Promise<void>;
};

export const ProblemBox = ({ problemSlug, onSubmit }: ProblemProps) => {
  const [activeTab, setActiveTab] = useState("description");
  const [problem, setProblem] = useState<Problem>({
    id: 0,
    title: "",
    slug: "",
    difficulty: "",
    tags: "",
    description: "",
    hints: [],
    answer: "",
    solution: "",
  });

  useEffect(() => {
    getProblemBySlug(problemSlug)
      .then((problem) => {
        setProblem(problem);
      })
      .catch((error) => {
        console.error("Error fetching problems:", error);
      });
  }, [problemSlug]);

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  if (problem.id === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="problem-box-container">
      <h1>{problem.title}</h1>
      <ProblemNavBar activeTab={activeTab} onTabClick={handleTabClick} />

      {activeTab === "description" && (
        <>
          <MarkTex>{problem.description}</MarkTex>
          <br></br>
          <AnswerInput onSubmit={onSubmit} />
          <br></br>
          {problem.hints.map((hint: any, i: number) => (
            <Accordian
              key={`hint-${i}`}
              title={`Hint ${i + 1}`}
              content={hint}
            />
          ))}
        </>
      )}

      {activeTab === "solution" && <MarkTex>{problem.solution}</MarkTex>}
    </div>
  );
};
