import { useState } from "react";

type AnswerInputProps = {
  onSubmit: (answer: string) => Promise<void>;
};

export const AnswerInput = ({ onSubmit }: AnswerInputProps) => {
  const [submission, setSubmission] = useState("");

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubmission(event.target.value);
  };

  const onFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await onSubmit(submission);
  };

  return (
    <>
      <form onSubmit={onFormSubmit}>
        <div className="answer-input-container">
          <input
            type="text"
            value={submission}
            onChange={onInputChange}
            placeholder="Your Answer"
          />
        </div>
        <button className="submit">Submit</button>
      </form>
    </>
  );
};
