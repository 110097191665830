const GET_JANE_STREET_PILOT_STRIPE_URL =
  "https://buy.stripe.com/fZefZibrv5r24M0eUV";

export function prefillStripeURLWithEmail(stripeURL: string, email: string) {
  const encodedEmail = encodeURIComponent(email);
  const separator = stripeURL.includes("?") ? "&" : "?";
  return `${stripeURL}${separator}prefilled_email=${encodedEmail}`;
}

export function getStripeURLWithPrefilledEmail(email: string) {
  return prefillStripeURLWithEmail(GET_JANE_STREET_PILOT_STRIPE_URL, email);
}
