import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./index.css";
import LandingPage from "./pages/LandingPage";
import LogInPage from "./pages/LogInPage";
import ProblemListPage from "./pages/ProblemListPage";
import ProblemPage from "./pages/ProblemPage";
import SignUpPage from "./pages/SignUpPage";
import reportWebVitals from "./reportWebVitals";
import { PilotPostPaymentPage } from "./pages/PilotPostPaymentPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/postPayment" element={<PilotPostPaymentPage />} />
        {/* <Route path="/logIn" element={<LogInPage />} />
        <Route path="/signUp" element={<SignUpPage />} />
        <Route path="/problems" element={<ProblemListPage />} />
        <Route path="/problems/:problemSlug" element={<ProblemPage />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
