import { useEffect, useState } from "react";

export const DEFAULT_ANNOUNCEMENT_DURATION_MS = 3000;

type AnnouncementBarProps = {
  message: string;
  duration_ms: number;
  onClose: () => void;
};

export const AnnouncementBar = ({
  message,
  duration_ms,
  onClose,
}: AnnouncementBarProps) => {
  const [barClass, setBarClass] = useState("announcement-bar");
  const ANIMATION_DURATION = 250;

  useEffect(() => {
    setBarClass("announcement-bar");

    // Start the timeout for the duration
    const timeoutId = setTimeout(() => {
      setBarClass("announcement-bar hide");
    }, duration_ms - ANIMATION_DURATION);

    // When the timeout to hide the bar expires, call onClose
    const closeTimeoutId = setTimeout(() => {
      onClose();
    }, duration_ms);

    // Clear timeouts when the component unmounts or if the duration changes
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(closeTimeoutId);
    };
  }, [duration_ms, onClose]);

  return <div className={barClass}>{message}</div>;
};
