import { useState } from "react";
import Confetti from "react-confetti";

// import { checkAnswer } from "../api/problemsAPI";
import { checkAnswer } from "../data";
import {
  AnnouncementBar,
  DEFAULT_ANNOUNCEMENT_DURATION_MS,
} from "../components/AnnouncementBar";
import { PaginationBar } from "../components/PaginationBar";
import { ProblemBox } from "../components/ProblemBox";
import { PilotSignUpForm } from "../components/PilotSignUpForm";

const LandingPage = () => {
  const localStoragePageNumberKey = "landingPagePageNumber";
  const problemSlugs = [
    "two-die-rolls-in-increasing-order",
    "unfair-coin-among-100-coins",
    "jane-street-interview-prep-ev",
  ];

  const totalPages = problemSlugs.length + 1;
  const [maxPage, setMaxPage] = useState(
    Number(localStorage.getItem(localStoragePageNumberKey)) + 1 || 1
  );
  const [currentPage, setCurrentPage] = useState(maxPage);
  const [announcement, setAnnouncement] = useState({
    show: false,
    message: "",
    key: 0,
  });
  const [showConfetti, setShowConfetti] = useState(false);

  const handleProblemSolved = (problemPageNumber: number) => {
    if (problemPageNumber + 1 > maxPage) {
      setMaxPage(problemPageNumber + 1);
      localStorage.setItem(localStoragePageNumberKey, maxPage.toString());
    }
  };

  const onPageChange = (newPage: number) => {
    // Don't change the page if newPage is greater than maxPage
    if (newPage > maxPage) {
      handleShowAnnouncement("You must solve all the previous problems first!");
    } else {
      setCurrentPage(newPage);
    }
  };

  const handleShowAnnouncement = (message: string) => {
    setAnnouncement((prev) => ({ show: true, message, key: prev.key + 1 }));
  };

  const handleCloseAnnouncement = () => {
    setAnnouncement((prev) => ({ ...prev, show: false }));
  };

  const onSubmit = async (answer: string) => {
    try {
      const response = await checkAnswer({
        slug: problemSlugs[currentPage - 1],
        answer: answer,
      });

      if (response.correct) {
        handleProblemSolved(currentPage);

        // Set a timer to stop showing confetti after 2 seconds
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000);
      }

      handleShowAnnouncement(
        response.correct
          ? "🎉 Correct answer! Solve the next problem!"
          : "😥 Incorrect answer. Try again!"
      );
    } catch (error) {
      if (error instanceof Error) {
        handleShowAnnouncement(
          error.message || "There was an error submitting your answer."
        );
      } else {
        handleShowAnnouncement("There was an error submitting your answer.");
      }
    }
  };

  let problemBoxes = problemSlugs.map((problemSlug) => (
    <ProblemBox
      key={problemSlug}
      problemSlug={problemSlug}
      onSubmit={onSubmit}
    />
  ));

  return (
    <div className="landing-page">
      {announcement.show && (
        <AnnouncementBar
          // Use key to remount the component
          key={announcement.key}
          message={announcement.message}
          duration_ms={DEFAULT_ANNOUNCEMENT_DURATION_MS}
          onClose={handleCloseAnnouncement}
        />
      )}
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={350}
          recycle={false}
          gravity={0.5}
        />
      )}

      <div className="landing-page-left-side">
        <div className="top-area">
          <div className="landing-page-logo">
            <h1>
              quant<span className="text-highlight">essential</span>
            </h1>
          </div>
        </div>
        <div className="landing-page-value-prop1">
          Passing quant interviews <strong>does not have to be hard</strong>.
          Get quant offers fast by studying the <strong>right</strong> way.
        </div>
        <div className="landing-page-value-prop2">
          Learn to solve these 30 real interview problems, and you can land a
          quant offer.
        </div>
      </div>
      <div className="landing-page-right-side">
        <div className="landing-page-problem-box-container">
          <PaginationBar
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          ></PaginationBar>
          {currentPage === totalPages ? (
            <PilotSignUpForm />
          ) : (
            problemBoxes[currentPage - 1]
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
